import '../scss/main.scss';
import $ from 'jquery';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'slicknav/dist/jquery.slicknav.min';
import 'slicknav/dist/slicknav.min.css';
import 'jquery-colorbox';
import 'jquery-colorbox/example3/colorbox.css';

'use strict';
/*global $:false, google:false*/
/*jshint globalstrict: true, browser: true*/

$(function () {
    $('#main-menu').slicknav();
});

var FooterMap = function () {
    var self = this,
        priv = {};

    self.load = function () {
        self.map = L.map('footer-map-canvas').setView([50.75192, 23.242949], 13);
        self.map.scrollWheelZoom.disable();
        self.map.on('focus', function () {
            self.map.scrollWheelZoom.enable();
        });
        self.map.on('blur', function () {
            self.map.scrollWheelZoom.disable();
        });

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 18,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(self.map);

        var iconObject = L.icon({
            iconUrl: '/images/map/marker-icon-black.png',
            shadowUrl: '/images/map/marker-shadow.png'
        });

        L.marker([50.75192, 23.242949], {icon: iconObject})
            .addTo(self.map)
            .bindPopup('ul. Sitaniec Osiedle 167, 22-400 Zamość')
            .openPopup();

        $('#footer-map-canvas').click(function () {
            $('#footer-map-canvas').css('height', '600px');
        });
    };
};
FooterMap = new FooterMap();
FooterMap.load();

var Gallery = function () {
    var self = this,
        priv = {};

    self.load = function () {
        priv.data_container = $('#gallery-photos');
        priv.listen();
    };

    priv.listen = function () {
        $(document).ready(function () {
            $('.gallery-photo a').colorbox({
                rel: 'gallery-photo-image',
                current: priv.data_container.data('colorbox-current'),
                previous: priv.data_container.data('colorbox-previous'),
                next: priv.data_container.data('colorbox-next'),
                close: priv.data_container.data('colorbox-close')
            });
        });
    };
};
Gallery = new Gallery();
if ($('.gallery-photo a').length) {
    Gallery.load();
}

var ProductCategory = function () {
    var self = this,
        priv = {};

    self.load = function () {
        priv.data_container = $('.product-list');
        priv.listen();
    };

    priv.listen = function () {
        $(document).ready(function () {
            $('.product-photo a').colorbox({
                rel: 'gallery-photo-image',
                current: priv.data_container.data('colorbox-current'),
                previous: priv.data_container.data('colorbox-previous'),
                next: priv.data_container.data('colorbox-next'),
                close: priv.data_container.data('colorbox-close')
            });
        });
    };
};
ProductCategory = new ProductCategory();
if ($('.product-photo a').length) {
    ProductCategory.load();
}

var HeaderBackground = function () {
    var self = this,
        priv = {};

    self.load = function () {
        priv.listen();
    };

    priv.listen = function () {
        $('#background-switch div').click(function () {
            priv.changeBackground($(this).data('background-id'));
        });
    };

    priv.changeBackground = function (number) {
        $('#background-switch div.current').removeClass('current');
        $('#background-switch div[data-background-id="' + number + '"]').addClass('current');
        $('#header-background').css('background-image', 'url(\'/images/header-backgrounds/background-'
            + number + '.jpg\')');
    };
};
HeaderBackground = new HeaderBackground();
HeaderBackground.load();
